import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";

import "./Separator2.scss";

class Separator2 extends Component {
  state: { rendered: boolean };

  constructor(props: any) {
    super(props);
    this.state = { rendered: false };
  }
  render() {
    return (
      <ScrollAnimation
        animateIn="fadeIn"
        animateOut="fadeOut"
        animateOnce={false}
        offset={100}
        duration={0}
        afterAnimatedIn={() => this.setState({ rendered: true })}
        afterAnimatedOut={() => this.setState({ rendered: false })}
        className="separator"
      >
        <div id="separator2-container">
          <div
            id="separator-line"
            className={`${
              this.state.rendered ? "separator-lineGrown" : undefined
            }`}
          />
        </div>
      </ScrollAnimation>
    );
  }
}

export default Separator2;
