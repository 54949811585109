import './Apparel.scss';

import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { NavLink } from 'react-router-dom';

import { Icon } from '@material-ui/core';

import { ApparelPageProps, ApparelProps, Category } from '../../../../Interfaces/Props';
import placeholder from '../../../../Media/graphics/logoCircle.svg';
import apparel from '../../../../Media/images/gear.jpg';
import wood from '../../../../Media/images/wood.jpg';
import Separator from '../../Separator/Separator';
import Separator2 from '../../Separator/Separator2';
import makeLink from '../../Tools/LinkMaker';

class Apparel extends Component<ApparelPageProps> {
  state: {
    sortedApparel: { [displayOrder: number]: ApparelProps[] };
    sortedCategories: Category[];
    search: string;
  };

  constructor(props: ApparelPageProps) {
    super(props);
    const sorted: { [displayOrder: number]: ApparelProps[] } = {};
    const keys: number[] = [];

    props.apparel
      .sort((a: ApparelProps, b: ApparelProps) => {
        if (a.category.displayOrder !== b.category.displayOrder)
          return a.category.displayOrder - b.category.displayOrder;
        else return a.title > b.title ? 1 : -1;
      })
      .map((apparel: ApparelProps) => {
        if (sorted[apparel.category.displayOrder])
          sorted[apparel.category.displayOrder].push(apparel);
        else {
          sorted[apparel.category.displayOrder] = [apparel];
          keys.push(apparel.category.displayOrder);
        }
        return null;
      });
    const categories: Category[] = keys
      .map((key: number) => sorted[key][0].category)
      .sort((a: Category, b: Category) => a.displayOrder - b.displayOrder);

    this.state = {
      sortedApparel: sorted,
      sortedCategories: categories,
      search: "",
    };
  }

  render() {
    let count = 0;
    const filtered: { [displayOrder: number]: ApparelProps[] } = {};
    let matches = 0;
    this.state.sortedCategories.map((category: Category) => {
      const results: ApparelProps[] = this.state.sortedApparel[
        category.displayOrder
      ].filter((apparel: ApparelProps) => {
        const apparelSearch = { ...apparel };
        apparelSearch.images = [];
        const result = Object.values(apparelSearch)
          .join(" ")
          .toLowerCase()
          .includes(this.state.search.toLowerCase());
        if (result) matches++;
        return result;
      });
      if (results) filtered[category.displayOrder] = results;
      return results;
    });

    return (
      <div id="apparel">
        <Helmet>
          <title>Caulfield Gear</title>
          <meta
            name="description"
            content="If it has a Caulfield Provision Company logo on it, we sell it here! Proudly don Caulfield Provision Company gear while cooking with our
          hot sauces!"
          />
        </Helmet>
        <div id="apparel-header" style={{ backgroundImage: `url(${apparel})` }}>
          <div id="apparel-headerText">Gear</div>
        </div>
        <div id="apparel-about">
          If it has a Caulfield Provision Company logo on it, we sell it here!
          Proudly don Caulfield Provision Company gear while cooking with our
          hot sauces!
        </div>
        <div id="apparel-search">
          <Icon id="apparel-searchIcon">search</Icon>
          <input
            id="apparel-searchInput"
            type="text"
            name="apparelsearch"
            placeholder="Search For apparel"
            value={this.state.search}
            onChange={(e) => this.setState({ search: e.target.value })}
          />
          <div id="apparel-resultCount">Showing {matches} Items</div>
        </div>
        <Separator2 />

        {matches === 0 && <div id="apparel-noResults">No Results</div>}
        <div
          id="apparel-listings"
          style={{
            backgroundImage: `url(${wood})`,
          }}
        >
          {this.state.sortedCategories.map((category: Category, i: number) => {
            if (filtered[category.displayOrder].length > 0) {
              count++;
              return (
                <div key={i} className="apparel-category">
                  {count > 1 && <Separator />}
                  <div className="apparel-categoryName">
                    {category.category}
                  </div>
                  <div className="apparel-categoryDescription">
                    {category.description}
                  </div>
                  <div className="apparel-categoryListings">
                    {filtered[category.displayOrder].map(
                      (apparel: ApparelProps, i: number) => (
                        <NavLink
                          exact={true}
                          to={`/gear/${makeLink(apparel.title)}`}
                          className="apparel-link"
                          key={i}
                          style={{ textDecoration: "none" }}
                        >
                          <div
                            className="apparel-listing"
                            style={{
                              backgroundImage: `url(${
                                apparel.images ? apparel.images[0] : placeholder
                              })`,
                            }}
                          >
                            <div className="apparel-apparelTitle">
                              {apparel.new && (
                                <div className="apparel-new">New</div>
                              )}
                              {apparel.title}
                            </div>
                          </div>
                        </NavLink>
                      )
                    )}
                  </div>
                </div>
              );
            } else return null;
          })}
        </div>
      </div>
    );
  }
}

export default Apparel;
