import './MenuIcon.scss';

import React from 'react';

import { MenuIconProps } from '../../../Interfaces/Props';
import green from '../../../Media/graphics/greenPepper.png';
import red from '../../../Media/graphics/redPepper.png';

class MenuIcon extends React.Component<MenuIconProps> {
  render() {
    return (
      <div
        className={`menu ${!this.props.closed ? "menu-closed" : "menu-open"}`}
      >
        <img
          src={green}
          className="menu-icon menu-pepperTop"
          alt="Caulfield Provision Hot Sauce Company Hotsauces"
        />
        <img
          src={green}
          className="menu-icon menu-pepperMiddle"
          alt="Caulfield Provision Hot Sauce Company Hotsauces"
        />

        <img
          src={green}
          className="menu-icon menu-pepperBottom"
          alt="Caulfield Provision Hot Sauce Company Hotsauces"
        />
        <img
          src={red}
          className="menu-icon menu-pepper menu-pepperRight"
          alt="Caulfield Provision Hot Sauce Company Hotsauces"
        />
        <img
          src={red}
          className="menu-icon menu-pepper menu-pepperLeft"
          alt="Caulfield Provision Hot Sauce Company Hotsauces"
        />
      </div>
    );
  }
}

export default MenuIcon;
