import './Footer.scss';

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import facebook from '../../../Media/graphics/facebook.png';
import logo from '../../../Media/graphics/footerLogo.png';
import instagram from '../../../Media/graphics/instagram.png';
import Bam from '../bam/Bam';

const sanityClient = require("@sanity/client");

class Footer extends Component {
  state: {
    facebook: string;
    instagram: string;
  };
  client: any;

  constructor(props: any) {
    super(props);
    this.state = {
      facebook: "",
      instagram: ""
    };
    this.client = sanityClient({
      projectId: "xg0sb2b3",
      dataset: "website-data",
      useCdn: true
    });
    this.client
      .fetch(`*[_type == "contactInfo"]{facebookLink, instagramLink}[0]`)
      .then((result: any) => {
        this.setState({
          facebook: result.facebookLink,
          instagram: result.instagramLink
        });
      });
  }

  render() {
    const date = new Date();
    const year = date.getFullYear();

    return (
      <div id="footer">
        <div id="footerLogoContainer">
          <NavLink exact={true} to="/" className="footer-logoLink">
            <img
              src={logo}
              id="footer-logo"
              alt="Caulfield Provision Hot Sauce Company Hotsauces caulfield logo"
            />
          </NavLink>
        </div>
        <div id="footer-linksContainer">{this.footerLinks()}</div>
        <div id="footer-info">
          <div id="footer-socialMedia">
            <div
              id="footer-facebookLink"
              onClick={() => (window.location.href = this.state.facebook)}
            >
              <img
                id="footer-facebookLogo"
                className="footer-socialMediaIcon"
                src={facebook}
                alt="Caulfield Provision Hot Sauce Company Hotsauces facebook link"
              />
            </div>
            <div
              id="footer-instagramLink"
              onClick={() => (window.location.href = this.state.instagram)}
            >
              <img
                id="footer-instagramLogo"
                className="footer-socialMediaIcon"
                src={instagram}
                alt="Caulfield Provision Hot Sauce Company Hotsauces instagram link"
              />
            </div>
          </div>
          <div id="footer-copyright">
            &copy; {year} Caulfield Provision Company
          </div>
          <Bam />
        </div>
      </div>
    );
  }

  footerLinks() {
    return (
      <ul id="footer-links">
        <li className="footer-linkContainer">
          <NavLink
            exact={true}
            to="/"
            className="footer-link"
            activeClassName="footer-link-active"
            isActive={this.isActive.bind(this, "/")}
          >
            <span className="footer-linkText">HOME</span>
          </NavLink>
        </li>
        <li className="footer-linkContainer">
          <NavLink
            exact={true}
            to="/about"
            className="footer-link"
            activeClassName="footer-link-active"
            isActive={this.isActive.bind(this, "/about")}
          >
            <span className="footer-linkText">ABOUT</span>
          </NavLink>
        </li>
        <li className="footer-linkContainer">
          <NavLink
            exact={true}
            to="/news"
            className="footer-link"
            activeClassName="footer-link-active"
            isActive={this.isActive.bind(this, "/news")}
          >
            <span className="footer-linkText">NEWS</span>
          </NavLink>
        </li>
        <li className="footer-linkContainer">
          <NavLink
            exact={true}
            to="/recipes"
            className="footer-link"
            activeClassName="footer-link-active"
            isActive={this.isActive.bind(this, "/recipes")}
          >
            <span className="footer-linkText">RECIPES</span>
          </NavLink>
        </li>
        <li className="footer-linkContainer">
          <NavLink
            exact={true}
            to="/provisions"
            className="footer-link"
            activeClassName="footer-link-active"
            isActive={this.isActive.bind(this, "/provisions")}
          >
            <span className="footer-linkText">PROVISIONS</span>
          </NavLink>
        </li>
        <li className="footer-linkContainer">
          <NavLink
            exact={true}
            to="/apparel"
            className="footer-link"
            activeClassName="footer-link-active"
            isActive={this.isActive.bind(this, "/gear")}
          >
            <span className="footer-linkText">GEAR</span>
          </NavLink>
        </li>
        <li className="footer-linkContainer">
          <NavLink
            exact={true}
            to="/find"
            className="footer-link"
            activeClassName="footer-link-active"
            isActive={this.isActive.bind(this, "/find")}
          >
            <span className="footer-linkText">FIND US</span>
          </NavLink>
        </li>
      </ul>
    );
  }

  // Workaround for NavLink not showing as active after
  // following a direct link
  isActive = (path: any, match: any, location: any) => {
    return !!(
      match ||
      location.pathname === path ||
      (path !== "/" && location.pathname.search(path) !== -1)
    );
  };
}

export default Footer;
