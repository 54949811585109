import './Recipes.scss';

import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { NavLink } from 'react-router-dom';

import { Icon } from '@material-ui/core';

import { RecipeProps, RecipesPageProps } from '../../../../Interfaces/Props';
import image from '../../../../Media/images/recipes.jpg';
import wood from '../../../../Media/images/wood.jpg';
import Separator2 from '../../Separator/Separator2';
import makeLink from '../../Tools/LinkMaker';

class Recipes extends Component<RecipesPageProps> {
  state: {
    sortedRecipes: RecipeProps[];
    search: string;
  };

  constructor(props: RecipesPageProps) {
    super(props);
    this.state = {
      sortedRecipes: this.props.recipes.sort(
        (a: RecipeProps, b: RecipeProps) => {
          const [monthA, dayA, yearA] = a.date.split("/");
          const [monthB, dayB, yearB] = b.date.split("/");
          if (yearA !== yearB) return +yearB - +yearA;
          if (monthA !== monthB) return +monthB - +monthA;
          return +dayB - +dayA;
        }
      ),
      search: "",
    };
  }

  render() {
    const filtered = this.state.sortedRecipes.filter((recipe: RecipeProps) => {
      const recipeSearch = { ...recipe };
      recipeSearch.images = [];
      return Object.values(recipeSearch)
        .join(" ")
        .toLowerCase()
        .includes(this.state.search.toLowerCase());
    });

    return (
      <div id="recipes">
        <Helmet>
          <title>Recipes</title>
          <meta
            name="description"
            content="Incorporate Caulfield Provision Company sauces into your cooking!"
          />
        </Helmet>
        <div id="recipes-header" style={{ backgroundImage: `url(${image})` }}>
          <div id="recipes-headerText">Recipes</div>
        </div>
        <div id="recipes-about">
          Wondering how to use our hot sauces for more than just a condiment?
          There are an unlimited number of ways to use our sauces to give your
          food some healthy flavor. We have recipes and suggestions for ways to
          use our sauces. Send us your ideas and perhaps we will include them in
          our recipes section!
        </div>

        <div id="recipes-search">
          <Icon id="recipes-searchIcon">search</Icon>
          <input
            id="recipes-searchInput"
            type="text"
            name="recipeSearch"
            placeholder="Search For Recipes"
            value={this.state.search}
            onChange={(e) => this.setState({ search: e.target.value })}
          />
          <div id="recipes-resultCount">Showing {filtered.length} Recipes</div>
        </div>
        <Separator2 />
        {filtered.length === 0 && <div id="recipes-noResults">No Results</div>}
        <div
          id="recipes-list"
          style={{
            backgroundImage: `url(${wood})`,
          }}
        >
          <div id="recipes-cards">
            {filtered.map((recipe: RecipeProps, i: number) => (
              <NavLink
                key={i}
                className="recipes-recipeLink"
                exact={true}
                to={`/recipes/${makeLink(recipe.title)}`}
                style={{ textDecoration: "none" }}
              >
                <div className="recipes-recipe">
                  <div className="recipes-recipeTitle">{recipe.title}</div>
                  {recipe.overview && (
                    <div className="recipes-recipeOverview">
                      {recipe.overview[0]}
                    </div>
                  )}
                  {recipe.images && (
                    <div
                      className="recipes-recipePicture"
                      style={{ backgroundImage: `url(${recipe.images[0]})` }}
                    />
                  )}
                  {!recipe.images && (
                    <>
                      <div className="recipes-ingredients">
                        <div className="recipes-ingredientsTitle">
                          Ingredients:
                        </div>
                        <ul className="recipes-ingredientsList">
                          {recipe.ingredients.map(
                            (ingredient: string, i: number) => (
                              <li key={i} className="recipes-ingredient">
                                {ingredient}
                              </li>
                            )
                          )}
                        </ul>
                      </div>

                      <div className="recipes-instructions">
                        <div className="recipes-instructionsTitle">
                          Instructions:
                        </div>
                        <ol className="recipes-instructionsList">
                          {recipe.instructions.map(
                            (instruction: string, i: number) => (
                              <li key={i} className="recipes-instruction">
                                {instruction}
                              </li>
                            )
                          )}
                        </ol>
                      </div>
                      <div className="recipes-readMore">Read More</div>
                    </>
                  )}
                </div>
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Recipes;
