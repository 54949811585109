import './InfoBar.scss';

import React, { Component } from 'react';

import { Icon } from '@material-ui/core';

import { InfoProps } from '../../../Interfaces/Props';
import facebook from '../../../Media/graphics/facebook.png';
import instagram from '../../../Media/graphics/instagram.png';

class InfoBar extends Component<InfoProps> {
  render() {
    return (
      <div id="info-infoBar">
        <div
          id="info-facebookLink"
          onClick={() => (window.location.href = this.props.facebook)}
        >
          <img
            id="info-facebookLogo"
            className="info-socialMediaIcon"
            src={facebook}
            alt="Caulfield Provision Hot Sauce Company Hotsauces facebook link"
          />
        </div>
        <div
          id="info-instagramLink"
          onClick={() => (window.location.href = this.props.instagram)}
        >
          <img
            id="info-instagramLogo"
            className="info-socialMediaIcon"
            src={instagram}
            alt="Caulfield Provision Hot Sauce Company Hotsauces instagram link"
          />
        </div>
        <a href={`tel:4436728237`} id="info-phone">
          <Icon className="info-infoIcon">phone</Icon>
          {this.props.phone}
        </a>
        <a
          href="mailto:sales@caulfieldprovisioncompany.com?subject=Sales Inquiry From Caulfield Website"
          target="_blank"
          rel="noopener noreferrer"
          id="info-email"
        >
          <Icon className="info-infoIcon">email</Icon>
          {this.props.email}
        </a>
      </div>
    );
  }
}

export default InfoBar;
