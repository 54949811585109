import React, { Component } from "react";
import { PromotionProps } from "../../../Interfaces/Props";

import "./PromotionsBar.scss";

class PromotionsBar extends Component<PromotionProps> {
  render() {
    return this.props.promo && <div id="promotionsBar">{this.props.promo}</div>;
  }
}

export default PromotionsBar;
