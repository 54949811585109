import './Home.scss';

import React, { Component } from 'react';
import Helmet from 'react-helmet';

import ImageCarousel from '../../ImageCarousel/ImageCarousel';
import Shop from '../../Shop/Shop';

class Home extends Component {
  render() {
    return (
      <div id="home">
        <Helmet>
          <title>Caulfield Provision Company</title>
          <meta
            name="description"
            content="Caulfield Provision Company, Potomac Maryland based makers of gourmet hot sauces packed full of flavor without bringing too much heat"
          />
        </Helmet>
        <ImageCarousel />
        <div className="home-container">
          <div id="home-overview">
            <div className="home-overviewParagraph">
              Caulfield Provision Company sauces are more than just hot sauces!
              They can be used for marinating, grilling, sautéing, or right out
              of the bottle as a condiment.
            </div>
            <div className="home-overviewParagraph">
              All of our sauces are intended to compliment your food, not
              overpower it. Our sauces are a healthy way to add flavor to your
              meals. They have no fat, are low in sugar and have little or no
              sodium.
            </div>
            <div className="home-overviewParagraph">
              We craft all our sauces in small, carefully controlled artisan
              style batches to ensure the highest quality product.
            </div>
          </div>
          <Shop />
        </div>
      </div>
    );
  }
}

export default Home;
