import './Nav.scss';

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { SlideDown } from 'react-slidedown';

import logoCircle from '../../../Media/graphics/logoCircle.svg';
import logo from '../../../Media/graphics/sticky.png';
import MenuIcon from './MenuIcon';

class Nav extends Component {
  state: {
    ready: boolean;
    showStickyNav: boolean;
    showingTopMobileLinks: boolean;
    showingStickyMobileLinks: boolean;
    displayingTopMobileMenu: boolean | undefined;
    displayingStickyMobileMenu: boolean | undefined;
    NavLinks: any;
  };
  client: any;

  constructor(props: any) {
    super(props);
    this.state = {
      ready: false,
      showStickyNav: false,
      showingTopMobileLinks: false,
      showingStickyMobileLinks: false,
      displayingTopMobileMenu: undefined,
      displayingStickyMobileMenu: undefined,
      NavLinks: (
        <ul
          className="nav-links"
          onClick={() =>
            this.setState({
              showingTopMobileLinks: false,
              showingStickyMobileLinks: false
            })
          }
        >
          <li className="nav-linkContainer">
            <NavLink
              exact={true}
              to="/"
              className="nav-link"
              activeClassName="nav-link-active"
              isActive={this.isActive.bind(this, "/")}
            >
              <span className="nav-linkText">HOME</span>
            </NavLink>
          </li>
          <li className="nav-linkContainer">
            <NavLink
              exact={true}
              to="/about"
              className="nav-link"
              activeClassName="nav-link-active"
              isActive={this.isActive.bind(this, "/about")}
            >
              <span className="nav-linkText">ABOUT</span>
            </NavLink>
          </li>
          <li className="nav-linkContainer">
            <NavLink
              exact={true}
              to="/news"
              className="nav-link"
              activeClassName="nav-link-active"
              isActive={this.isActive.bind(this, "/news")}
            >
              <span className="nav-linkText">NEWS</span>
            </NavLink>
          </li>
          <li className="nav-linkContainer">
            <NavLink
              exact={true}
              to="/recipes"
              className="nav-link"
              activeClassName="nav-link-active"
              isActive={this.isActive.bind(this, "/recipes")}
            >
              <span className="nav-linkText">RECIPES</span>
            </NavLink>
          </li>
          <li className="nav-linkContainer">
            <NavLink
              exact={true}
              to="/provisions"
              className="nav-link"
              activeClassName="nav-link-active"
              isActive={this.isActive.bind(this, "/provisions")}
            >
              <span className="nav-linkText">PROVISIONS</span>
            </NavLink>
          </li>
          <li className="nav-linkContainer">
            <NavLink
              exact={true}
              to="/gear"
              className="nav-link"
              activeClassName="nav-link-active"
              isActive={this.isActive.bind(this, "/gear")}
            >
              <span className="nav-linkText">GEAR</span>
            </NavLink>
          </li>
          <li className="nav-linkContainer">
            <NavLink
              exact={true}
              to="/find"
              className="nav-link"
              activeClassName="nav-link-active"
              isActive={this.isActive.bind(this, "/find")}
            >
              <span className="nav-linkText">FIND US</span>
            </NavLink>
          </li>
        </ul>
      )
    };
  }

  componentDidMount() {
    this.setState({ ready: true });
    if (window) {
      window.addEventListener("scroll", e => this.checkScrollPosition());
      window.addEventListener("resize", () => this.handleResize());
      this.handleResize();
    }
  }

  handleResize() {
    this.setState({
      showingStickyMobileLinks: false,
      showingTopMobileLinks: false
    });
    if (window) {
      const top = document.getElementById("nav-menuIconTop");
      if (top) {
        this.setState({
          displayingTopMobileMenu: !(
            window.getComputedStyle(top).display === "none"
          )
        });
      }

      const sticky = document.getElementById("nav-stickyMenuIcon");
      if (sticky) {
        this.setState({
          displayingStickyMobileMenu: !(
            window.getComputedStyle(sticky).display === "none"
          )
        });
      }
    }
  }

  checkScrollPosition() {
    if (window) {
      const position = window.pageYOffset;
      const navBar = document.getElementById("nav");
      if (!navBar) return;
      if (position - navBar.clientHeight > 50) {
        this.setState({ showStickyNav: true });
      } else {
        this.setState({
          showStickyNav: false,
          showingStickyMobileLinks: false
        });
      }
    }
  }

  // Workaround for NavLink not showing as active after
  // following a direct link
  isActive = (path: any, match: any, location: any) => {
    return !!(
      match ||
      location.pathname === path ||
      (path !== "/" && location.pathname.search(path) !== -1)
    );
  };

  render() {
    return (
      <div
        id="nav"
        style={{ visibility: this.state.ready ? "visible" : "hidden" }}
      >
        <div id="nav-navigationSpacer">
          <div id="nav-navigation">
            <NavLink exact={true} to="/" id="nav-logoHomeLink">
              <img
                id="nav-logo"
                src={logoCircle}
                alt="Caulfield Provision Hot Sauce Company Hotsauces"
              />
            </NavLink>
            <div id="nav-topMenuContainer" className="nav-menuContainer">
              <div
                className="nav-menuIcon"
                id="nav-menuIconTop"
                onClick={() =>
                  this.setState({
                    showingTopMobileLinks: !this.state.showingTopMobileLinks
                  })
                }
              >
                <MenuIcon closed={this.state.showingTopMobileLinks} />
              </div>
            </div>
            <SlideDown
              className="nav-linksSlidedown"
              closed={
                this.state.displayingTopMobileMenu === undefined ||
                (this.state.displayingTopMobileMenu &&
                  !this.state.showingTopMobileLinks)
              }
              transitionOnAppear={false}
            >
              {this.state.NavLinks}
            </SlideDown>
          </div>
        </div>

        <div
          id="nav-stickyNav"
          className={
            this.state.showStickyNav ? "nav-stickyNavShown" : undefined
          }
        >
          <NavLink exact={true} to="/" id="nav-stickyLogoHomeLink">
            <img
              id="nav-stickyLogo"
              src={logo}
              alt="Caulfield Provision Hot Sauce Company Hotsauces"
            />
          </NavLink>

          <div id="nav-stickyMenuContainer" className="nav-menuContainer">
            <div
              className="nav-menuIcon"
              id="nav-stickyMenuIcon"
              onClick={() =>
                this.setState({
                  showingStickyMobileLinks: !this.state.showingStickyMobileLinks
                })
              }
            >
              <MenuIcon closed={this.state.showingStickyMobileLinks} />
            </div>
          </div>

          <SlideDown
            className="nav-linksSlidedown"
            closed={
              this.state.displayingStickyMobileMenu === undefined ||
              (this.state.displayingStickyMobileMenu &&
                !this.state.showingStickyMobileLinks)
            }
            transitionOnAppear={true}
          >
            {this.state.NavLinks}
          </SlideDown>
        </div>
      </div>
    );
  }
}

export default Nav;
