import './Shop.scss';

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import provisions from '../../../Media/graphics/bottle.png';
import apparel from '../../../Media/graphics/shirt.png';

class Shop extends Component {
  render() {
    return (
      <div className="shop">
        <div className="shop-title">Shop</div>
        <div className="shop-item">
          <NavLink exact={true} to="/provisions" className="shop-link">
            <div className="shop-circle">
              <img
                className="shop-icon shop-provisions"
                src={provisions}
                alt="Caulfield Provision Hot Sauce Company Hotsauces"
              />
            </div>
            <div className="shop-itemName"> Provisions</div>
          </NavLink>
        </div>
        <div className="shop-item">
          <NavLink exact={true} to="/gear" className="shop-link">
            <div className="shop-circle">
              <img
                className="shop-icon"
                src={apparel}
                alt="Caulfield Provision Hot Sauce Company Hotsauces"
              />
            </div>
            <div className="shop-itemName">Gear</div>
          </NavLink>
        </div>
      </div>
    );
  }
}

export default Shop;
