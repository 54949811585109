import './Article.scss';

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { Icon } from '@material-ui/core';

import { NewsProps } from '../../../Interfaces/Props';
import TextProcessor from '../../../utils/TextProcessor';
import Separator from '../Separator/Separator';

class Article extends Component<NewsProps> {
  state: {
    selectedImage: string | undefined;
    multipleImages: boolean;
  };
  resizeDelay: number = 0;

  constructor(props: NewsProps) {
    super(props);
    this.state = {
      selectedImage:
        this.props.images !== undefined ? this.props.images[0] : undefined,
      multipleImages:
        this.props.images !== undefined && this.props.images.length > 1
    };
  }

  componentDidMount() {
    if (window) {
      window.addEventListener("resize", () => {
        clearInterval(this.resizeDelay);
        this.resizeDelay = window.setTimeout(() => this.styleWidth(), 200);
      });
    }
    this.styleWidth();
  }

  styleWidth() {
    const images = this.props.images
      ? this.props.images.length > 1
        ? document.getElementsByClassName("article-imageTiles")[0]
        : document.getElementsByClassName("article-imageMain")[0]
      : null;
    const textContent = document.getElementsByClassName(
      "article-textContent"
    )[0];
    const fullWidth = document.getElementsByClassName("article-fullWidth")[0];

    if (
      !images ||
      images.getBoundingClientRect().bottom <=
        textContent.getBoundingClientRect().top
    ) {
      return;
    }

    Array.from(fullWidth.childNodes).map((node: ChildNode) =>
      fullWidth.removeChild(node)
    );

    const resizeItems = Array.from(
      document.querySelectorAll(".article-resizable")
    );

    let listTitle: any = null;
    let listContent = document.createElement("ul");
    listContent.classList.add("article-list");
    listContent.classList.add("article-componentFullWidth");

    const baseline = images.getBoundingClientRect().bottom;
    let baselineReached = false;
    let currentRow = 0;
    let newRow = false;
    for (let item of resizeItems) {
      item.classList.remove("article-componentFullWidth");
      if (item.getBoundingClientRect().top > currentRow) {
        currentRow = item.getBoundingClientRect().top;
        newRow = true;
      } else {
        newRow = false;
      }

      if (
        baselineReached ||
        (item.getBoundingClientRect().top >= baseline && newRow)
      ) {
        baselineReached = true;
        item.classList.add("article-componentFullWidth");
        if (item.classList.contains("article-listTitle"))
          listTitle = item.cloneNode(true);
        if (item.classList.contains("article-listItem"))
          listContent.appendChild(item.cloneNode(true));
      } else item.classList.remove("article-componentFullWidth");
    }

    Array.from(fullWidth.childNodes).map((node: ChildNode) =>
      fullWidth.removeChild(node)
    );

    const paragraphs = document.getElementsByClassName("article-paragraph");
    const words = document.getElementsByClassName("article-paragraphWord");
    Array.from(words)
      .filter((word: Element) =>
        word.parentElement ? word.parentElement.firstChild === word : false
      )
      .map((firstChild: Element) =>
        firstChild.parentElement
          ? firstChild.classList.contains("article-componentFullWidth")
            ? firstChild.parentElement.classList.add(
                "article-paragraphFullWidthOnly"
              )
            : firstChild.parentElement.classList.remove(
                "article-paragraphFullWidthOnly"
              )
          : null
      );

    Array.from(paragraphs).map((elt: Element) => {
      if (elt.innerHTML.includes("article-componentFullWidth")) {
        elt.classList.add("article-componentFullWidth");
        if (
          elt.firstChild &&
          Object.values(elt.firstChild)[1] &&
          Object.values(elt.firstChild)[1]
            .className.toString()
            .includes("article-componentFullWidth")
        )
          elt.classList.add("article-paragraphFullWidthOnly");
        fullWidth.appendChild(elt.cloneNode(true));
      } else elt.classList.remove("article-componentFullWidth");
      return null;
    });

    if (listTitle) fullWidth.appendChild(listTitle);
    if (listContent.hasChildNodes()) fullWidth.appendChild(listContent);
  }

  render() {
    return (
      <div className="article">
        <div className="article-header">
          <NavLink className="article-backLink" exact={true} to="/news">
            <div className="article-back">
              <Icon className="article-backIcon">reply</Icon>
              All News
            </div>
          </NavLink>
          <div className="article-date">{this.props.date}</div>
        </div>
        <div className="article-title">{this.props.title}</div>
        <Separator />
        <div className="article-content">
          {this.props.images && (
            <div className="article-images">
              <div
                className="article-imageMain"
                style={{
                  backgroundImage: `url(${this.state.selectedImage})`
                }}
              />
              {this.props.images && this.state.multipleImages && (
                <div className="article-imageTiles">
                  {this.props.images.map((image: string, i: number) => {
                    return (
                      <div
                        key={i}
                        className={`article-imageTile ${
                          image === this.state.selectedImage
                            ? "imageTileSelected"
                            : undefined
                        }`}
                        style={{ backgroundImage: `url(${image}` }}
                        onClick={() => this.setState({ selectedImage: image })}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          )}
          <div
            className={`article-textContent ${
              !this.props.images ? "article-textContentFullWidth" : ""
            }`}
          >
            {this.props.content && (
              <div className="article-text">
                {this.props.content.map((paragraph: string, i: number) => (
                  <div className="article-paragraph article-resizable" key={i}>
                    <TextProcessor links={this.props.links} text={paragraph} />
                    {/* {paragraph.split(" ").map((word: string, i: number) => (
                      <span
                        key={i}
                        className="article-paragraphWord article-resizable"
                      >
                        {`${word} `}
                      </span>
                    ))} */}
                  </div>
                ))}
              </div>
            )}
            {this.props.list && (
              <div className="article-listContainer">
                {this.props.list.listTitle && (
                  <div className="article-listTitle article-resizable">
                    {this.props.list.listTitle}
                  </div>
                )}
                <ul className="article-list ">
                  {this.props.list.listContent.map(
                    (item: string, i: number) => {
                      return (
                        <li
                          key={i}
                          className="article-listItem article-resizable"
                        >
                          <TextProcessor links={this.props.links} text={item} />
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            )}
          </div>
          <div className="article-fullWidth" />
        </div>
        {this.props.tags && (
          <div className="article-tags">
            {this.props.tags.map((tag: string) => tag)}
          </div>
        )}
      </div>
    );
  }
}

export default Article;
