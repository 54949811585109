import './ApparelItem.scss';

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { Icon } from '@material-ui/core';

import { ApparelProps } from '../../../Interfaces/Props';
import placeholder from '../../../Media/graphics/logoCircle.svg';
import Separator from '../Separator/Separator';

class ApparelItem extends Component<ApparelProps> {
  state: {
    selectedImage: string;
    multipleImages: boolean;
    purchaseOptions: boolean;
    selectedOption: {
      optionPrice: number;
      option: string;
      optionWeight: number;
    };
    quantity: number;
  };
  constructor(props: ApparelProps) {
    super(props);
    this.state = {
      selectedImage: this.props.images ? this.props.images[0] : placeholder,
      multipleImages:
        this.props.images !== undefined && this.props.images.length > 1,
      purchaseOptions: this.props.options !== undefined,
      selectedOption: this.props.options[0],
      quantity: 1,
    };
  }

  render() {
    return (
      <div className="apparel">
        <div className="apparel-header">
          <NavLink className="apparel-backLink" to="/gear" exact={true}>
            <div className="apparel-back">
              <Icon className="apparel-backIcon">reply</Icon>
              All Apparel
            </div>
          </NavLink>
        </div>
        <div className="apparel-title">{this.props.title}</div>
        <div className="apparel-content">
          {this.props.description.length > 0 && (
            <div className="apparel-description">
              {this.props.description.map((paragraph: string, i: number) => (
                <div className="apparel-paragraph" key={i}>
                  {paragraph}
                </div>
              ))}
            </div>
          )}
          <Separator />
          <div className="apparel-images">
            <div
              className="apparel-imageMain"
              style={{ backgroundImage: `url(${this.state.selectedImage})` }}
            />
            {this.props.images && this.state.multipleImages && (
              <div className="apparel-imageTiles">
                {this.props.images.map((image: string, i: number) => {
                  return (
                    <div
                      key={i}
                      className={`apparel-imageTile ${
                        image === this.state.selectedImage
                          ? "imageTileSelected"
                          : undefined
                      }`}
                      style={{ backgroundImage: `url${image}` }}
                      onClick={() => this.setState({ selectedImage: image })}
                    />
                  );
                })}
              </div>
            )}
          </div>
          <div className="apparel-about">
            <div className="apparel-price">
              $
              {(
                this.state.selectedOption.optionPrice * this.state.quantity
              ).toFixed(2)}
              <div className="apparel-quantity">
                <input
                  className="apparel-quantityInput"
                  type="number"
                  min={1}
                  step={1}
                  value={this.state.quantity}
                  onChange={(e: any) =>
                    this.setState({ quantity: Math.max(1, e.target.value) })
                  }
                />
              </div>
              <div className="apparel-addToCart">
                <button
                  className="apparel-addButton snipcart-add-item"
                  data-item-id={
                    this.props.title + " " + this.state.selectedOption.option
                  }
                  data-item-name={
                    this.props.title + " " + this.state.selectedOption.option
                  }
                  data-item-weight={this.state.selectedOption.optionWeight}
                  data-item-price={`${this.props.options[0].optionPrice.toFixed(
                    2
                  )}`}
                  data-item-quantity={`${this.state.quantity}`}
                  data-item-taxable="true"
                  data-item-url="/index.html"
                >
                  Add to Cart
                </button>
              </div>
            </div>

            <div className="apparel-options">
              {this.props.options.map(
                (
                  option: { optionPrice: number; option: string },
                  i: number
                ) => {
                  return (
                    <div
                      key={i}
                      className={`apparel-option ${
                        option === this.state.selectedOption
                          ? "apparel-optionSelected"
                          : undefined
                      }`}
                      onClick={() => this.setState({ selectedOption: option })}
                    >
                      {option.option}
                    </div>
                  );
                }
              )}
            </div>

            {this.props.notes && (
              <div className="apparel-notesContainer">
                Notes:
                <div className="appael-notes">{this.props.notes}</div>
              </div>
            )}
          </div>
        </div>
        {this.props.tags && (
          <div className="apparel-tags">
            {this.props.tags.map((tag: string) => tag)}
          </div>
        )}
      </div>
    );
  }
}

export default ApparelItem;
