import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "es6-object-assign/auto";

import React from "react";
import "./index.scss";
import App from "./App/App.tsx";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { render } from "react-snapshot";

const root = document.getElementById("root");
render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  root
);

serviceWorker.unregister();
