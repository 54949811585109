import './Find.scss';

import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { NavLink } from 'react-router-dom';

import { Icon } from '@material-ui/core';

import { EventProps } from '../../../../Interfaces/Props';
import find from '../../../../Media/images/map.jpg';
import Separator from '../../Separator/Separator';
import makeLink from '../../Tools/LinkMaker';

const sanityClient = require("@sanity/client");

class Find extends Component {
  state: {
    events: EventProps[];
    address: string;
    phone: string;
    email: string;
    map: string;
    locations: string[];
  };
  client: any;

  constructor(props: any) {
    super(props);
    this.state = {
      events: [],
      address: "",
      email: "",
      phone: "",
      map: "",
      locations: [],
    };

    this.client = sanityClient({
      projectId: "xg0sb2b3",
      dataset: "website-data",
      useCdn: true,
    });

    this.client
      .fetch(
        `*[_type == "contactInfo"]{phoneNumber, emailAddress, address, mapURL}[0]`
      )
      .then((result: any) =>
        this.setState({
          address: result.address,
          email: result.emailAddress,
          phone: result.phoneNumber,
          map: result.mapURL,
        })
      );

    this.client
      .fetch(`*[_type == "location"]{locationName}`)
      .then((results: any) =>
        this.setState({
          locations: results.map((result: any) => {
            return result.locationName;
          }),
        })
      );

    this.client
      .fetch(
        `*[_type == "specialEvent"]{eventTitle, eventOverview, eventLink, eventDates[]{eventDate, time}}`
      )
      .then((results: any) =>
        this.setState({
          events: results
            .map((result: any) => {
              let event: EventProps = {
                title: result.eventTitle ? result.eventTitle : "",
                date: result.eventDates ? result.eventDates : undefined,
              };
              if (result.eventOverview)
                event = { ...event, overview: result.eventOverview };
              if (result.eventLink)
                event = { ...event, link: result.eventLink };

              return event;
            })
            .filter((event: EventProps) => {
              if (event.date === undefined || event.date.length === 0)
                return false;
              const now = new Date();
              const eventTime = new Date(
                event.date[event.date.length - 1].eventDate
              );
              return now.getTime() - eventTime.getTime() <= 1000 * 24 * 3600;
            })
            .sort((a: EventProps, b: EventProps) => {
              if (a.date === undefined || b.date === undefined) return false;
              const [yearA, monthA, dayA] = a.date[0].eventDate.split("-");
              const [yearB, monthB, dayB] = b.date[0].eventDate.split("-");
              if (yearA !== yearB) return +yearA - +yearB;
              if (monthA !== monthB) return +monthA - +monthB;
              return +dayA - +dayB;
            }),
        })
      );

    this.client
      .fetch(`*[_type == "location"]{locationName}`)
      .then((results: any) =>
        this.setState({
          locations: results.map((result: any) => result.locationName),
        })
      );
  }

  americanizeDate(date: string) {
    let splitDate = date.split("-");
    return [splitDate[1], splitDate[2], splitDate[0]].join("/");
  }

  render() {
    return (
      <div id="find">
        <Helmet>
          <title>Find Us In Stores</title>
          <meta
            name="description"
            content="All of Caulfield Provision Company's exciting upcoming events and store locations that sell our products. Come try some sauce!"
          />
        </Helmet>
        <div id="find-header" style={{ backgroundImage: `url(${find})` }}>
          <div id="find-headerText">Find Us</div>
        </div>

        <div className="find-wood">
          {this.state.locations.length > 0 && (
            <>
              <div id="find-locations">
                Find in Stores
                <div id="find-locationsSubheader">
                  Find our provisions in store at the following locations.
                </div>
                <div id="find-locationsSubheader">
                  Check back, as we are always expanding!
                </div>
                ​
                {this.state.locations.map((location: string, i: number) => (
                  <NavLink
                    key={i}
                    className="find-locationLink"
                    exact={true}
                    to={`/find/${makeLink(location)}`}
                  >
                    {location}
                  </NavLink>
                ))}
              </div>
              <Separator />
            </>
          )}
          {this.state.events.length > 0 && (
            <>
              <div id="find-events">
                Upcoming Events:
                {this.state.events.map((event: EventProps, i: number) => (
                  <div key={i} className="find-event">
                    {event.link !== undefined && (
                      <a className="find-eventLink" href={event.link}>
                        <div className="find-eventTitle">
                          {event.title}:
                          <div className="find-eventDate">
                            {event.date !== undefined &&
                              event.date.map(
                                (
                                  date: { eventDate: string; time: string },
                                  i: number
                                ) =>
                                  i === event.date!.length - 1
                                    ? this.americanizeDate(date.eventDate) +
                                      ": " +
                                      date.time
                                    : this.americanizeDate(date.eventDate) +
                                      ": " +
                                      date.time +
                                      ", "
                              )}
                          </div>
                        </div>
                      </a>
                    )}
                    {event.link === undefined && (
                      <div className="find-eventTitle">
                        {event.title}:
                        <div className="find-eventDate">
                          {event.date !== undefined &&
                            event.date.map(
                              (
                                date: { eventDate: string; time: string },
                                i: number
                              ) =>
                                i === event.date!.length - 1
                                  ? this.americanizeDate(date.eventDate) +
                                    ": " +
                                    date.time
                                  : this.americanizeDate(date.eventDate) +
                                    ": " +
                                    date.time +
                                    ", "
                            )}
                        </div>
                      </div>
                    )}
                    {event.overview && (
                      <div className="find-eventOverview">{event.overview}</div>
                    )}
                  </div>
                ))}
              </div>
              <Separator />
            </>
          )}

          <div id="find-contact">
            Contact Us
            <a href={`tel:4436728237`} className="find-contactMethod">
              <Icon className="find-contactIcon">phone</Icon>
              {this.state.phone}
            </a>
            <a
              href="mailto:sales@caulfieldprovisioncompany.com?subject=Sales Inquiry From Caulfield Website"
              target="_blank"
              rel="noopener noreferrer"
              className="find-contactMethod"
            >
              <Icon className="find-contactIcon">email</Icon>
              {this.state.email}
            </a>
            <div className="find-contactMethod" id="find-address">
              <Icon className="find-contactIcon">place</Icon>
              {this.state.address}
            </div>
          </div>
        </div>
        <iframe title="map" id="find-map" src={`${this.state.map}`} />
      </div>
    );
  }
}

export default Find;
