import React, { Component } from "react";
import { RecipeProps } from "../../../Interfaces/Props";
import { Icon } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Separator from "../Separator/Separator";

import "./Recipe.scss";

class Recipe extends Component<RecipeProps> {
  state: {
    selectedImage: string | undefined;
    multipleImages: boolean;
    baseline: number | undefined;
  };
  resizeDelay: number = 0;

  constructor(props: RecipeProps) {
    super(props);
    this.state = {
      selectedImage:
        this.props.images !== undefined ? this.props.images[0] : undefined,
      multipleImages:
        this.props.images !== undefined && this.props.images.length > 1,
      baseline: undefined
    };
  }

  componentDidMount() {
    if (window) {
      window.addEventListener("resize", () => {
        clearInterval(this.resizeDelay);
        this.resizeDelay = window.setTimeout(() => this.styleListWidth(), 200);
      });
    }
    this.styleListWidth();
  }

  styleListWidth() {
    const ingredients = document.getElementsByClassName(
      "recipe-ingredients"
    )[0];
    const images = document.getElementById("recipe-images");

    if (
      images &&
      ingredients &&
      images.getBoundingClientRect().bottom <=
        ingredients.getBoundingClientRect().top
    ) {
      return;
    }
    const listItems = Array.from(
      document.querySelectorAll(".recipe-listComponent")
    );
    const fullWidth = document.getElementsByClassName("recipe-fullWidth")[0];
    let ingredientsList = document.createElement("ul");
    ingredientsList.classList.add("recipe-ingredientsList");
    let instructionsList = document.createElement("ol");
    instructionsList.classList.add("recipe-instructionsList");
    if (
      images &&
      listItems &&
      fullWidth &&
      instructionsList &&
      ingredientsList
    ) {
      Array.from(fullWidth.childNodes).map((node: ChildNode) =>
        fullWidth.removeChild(node)
      );
      let ingredientsHeader: any = null;
      let instructionsHeader: any = null;
      let instructionsStartIndex = 1;

      const baseline = images.getBoundingClientRect().bottom;

      for (let item of listItems) {
        if (item.getBoundingClientRect().top > baseline) {
          item.classList.add("recipe-listComponentFullWidth");
          if (item.classList.contains("recipe-ingredientsHeader"))
            ingredientsHeader = item.cloneNode(true);
          else if (item.classList.contains("recipe-ingredient"))
            ingredientsList.appendChild(item.cloneNode(true));
          else if (item.classList.contains("recipe-instructionsHeader"))
            instructionsHeader = item.cloneNode(true);
          else if (item.classList.contains("recipe-instruction"))
            instructionsList.appendChild(item.cloneNode(true));
        } else {
          if (
            item.classList.contains("recipe-instruction") &&
            !item.classList.contains("recipe-listComponentFullWidth")
          )
            instructionsStartIndex++;
          item.classList.remove("recipe-listComponentFullWidth");
        }
      }
      instructionsList.setAttribute("start", `${instructionsStartIndex}`);
      if (ingredientsHeader) fullWidth.appendChild(ingredientsHeader);
      if (ingredientsList.hasChildNodes())
        fullWidth.appendChild(ingredientsList);
      if (instructionsHeader) fullWidth.appendChild(instructionsHeader);
      if (instructionsList.hasChildNodes())
        fullWidth.appendChild(instructionsList);
    }
  }

  render() {
    return (
      <div className="recipe">
        <div className="recipe-header">
          <NavLink className="recipe-backLink" exact={true} to="/recipes">
            <div className="recipe-back">
              <Icon className="recipe-backIcon">reply</Icon>
              All Recipes
            </div>
          </NavLink>
          <div className="recipe-date">Posted {this.props.date}</div>
        </div>
        <div className="recipe-title">{this.props.title}</div>
        {this.props.overview && <Separator />}
        {this.props.overview && (
          <div className="recipe-overview">
            {this.props.overview.map((paragraph: string, i: number) => (
              <div key={i} className="recipe-overviewParagraph">
                {paragraph}
              </div>
            ))}
          </div>
        )}
        <Separator />
        <div className="recipe-content">
          {this.props.images && (
            <div id="recipe-images">
              <div
                className="recipe-imageMain"
                style={{ backgroundImage: `url(${this.state.selectedImage})` }}
              />
              {this.state.multipleImages && (
                <div className="recipe-imageTiles">
                  {this.props.images.map((image: string, i: number) => {
                    return (
                      <div
                        key={i}
                        className={`recipe-imageTile ${
                          image === this.state.selectedImage
                            ? "imageTileSelected"
                            : undefined
                        }`}
                        style={{ backgroundImage: `url(${image})` }}
                        onClick={() => this.setState({ selectedImage: image })}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          )}
          <div
            className={`recipe-directions ${
              !this.props.images ? "recipe-directionsFullWidth" : undefined
            }`}
          >
            <div className="recipe-ingredients">
              <div className="recipe-listComponent recipe-ingredientsHeader">
                Ingredients:
              </div>
              <ul className="recipe-ingredientsList">
                {this.props.ingredients.map((ingredient: string, i: number) => {
                  return (
                    <li
                      key={i}
                      className="recipe-ingredient recipe-listComponent recipe-listItem"
                    >
                      {ingredient}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="recipe-instructions">
              <div className="recipe-listComponent recipe-instructionsHeader">
                Instructions:
              </div>
              <ol className="recipe-instructionsList">
                {this.props.instructions.map(
                  (instruction: string, i: number) => {
                    return (
                      <li
                        key={i}
                        className="recipe-instruction recipe-listComponent recipe-listItem"
                      >
                        {instruction}
                      </li>
                    );
                  }
                )}
              </ol>
            </div>
          </div>
        </div>
        <div className="recipe-fullWidth" />
        {this.props.tags && (
          <div className="recipe-tags">
            {this.props.tags.map((tag: string) => tag)}
          </div>
        )}
      </div>
    );
  }
}

export default Recipe;
