function makeLink(original: string) {
  return encodeURIComponent(
    original
      .split(" ")
      .join("_")
      .replace(/[^a-zA-Z ]/g, "")
  );
}

export default makeLink;
