import './About.scss';

import React, { Component } from 'react';
import Helmet from 'react-helmet';

import family from '../../../../Media/images/show2.jpg';
import Shop from '../../Shop/Shop';

class About extends Component {
  render() {
    return (
      <div id="about">
        <Helmet>
          <title>Caulfield Provision Company</title>
          <meta
            name="description"
            content="Learn more about Caulfield Provision Company."
          />
        </Helmet>
        <div id="about-header" style={{ backgroundImage: `url(${family})` }}>
          <div id="about-headerText">About Us</div>
        </div>
        <div className="about-woodBackground">
          <div id="about-text">
            <div className="about-textParagraph">
              Caulfield Provision Company is a woman owned, family operated
              business. Connie Caulfield has spent over two and a half decades
              as a stay home mom. During the course of that time, her passion
              has always been creating homemade, healthy meals for her husband
              and their four children. She has loved ensuring their meals are
              healthy, while constantly experimenting with various cuisines, and
              flavors.
            </div>
            <div className="about-textParagraph">
              Caulfield Provision Company’s flavorful and healthy hot sauces
              evolved out of this long time passion.
            </div>
          </div>
          <Shop />
        </div>
      </div>
    );
  }
}

export default About;
