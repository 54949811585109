import React, { Component } from "react";
import { LocationProps, StoreProps } from "../../../Interfaces/Props";
import Separator from "../Separator/Separator";
import { Icon } from "@material-ui/core";

import "./Location.scss";
import { NavLink } from "react-router-dom";

class Location extends Component<LocationProps> {
  render() {
    return (
      <div className="location">
        <div className="location-backBar">
          <NavLink className="location-backLink" to="/find" exact={true}>
            <div className="location-back">
              <Icon className="location-backIcon">reply</Icon>
              All Locations
            </div>
          </NavLink>
        </div>
        <div className="location-header">Caulfield Provisions In:</div>
        <div className="location-title">{this.props.name}</div>
        <Separator />
        {this.props.stores.map((store: StoreProps, i: number) => (
          <div key={i} className="location-store">
            {store.link !== undefined && (
              <a className="location-link" href={"http://" + store.link}>
                <div className="location-storeName">{store.name}</div>
              </a>
            )}
            {store.link === undefined && (
              <div className="location-storeName">{store.name}</div>
            )}
            <div className="location-info">
              <Icon className="location-infoIcon">place</Icon>
              {store.address}
            </div>
            <div className="location-info">
              <Icon className="location-infoIcon">phone</Icon>
              {store.phone}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Location;
