import './Provision.scss';

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { Icon } from '@material-ui/core';

import { ProvisionProps } from '../../../Interfaces/Props';
import placeholder from '../../../Media/graphics/logoCircle.svg';
import Separator from '../Separator/Separator';

class Provision extends Component<ProvisionProps> {
  state: {
    selectedImage: string;
    multipleImages: boolean;
    quantity: number;
    selectedOption: {
      optionPrice: number;
      option: string;
      optionWeight: number;
    };
  };

  constructor(props: ProvisionProps) {
    super(props);
    this.state = {
      selectedImage: this.props.images ? this.props.images[0] : placeholder,
      multipleImages:
        this.props.images !== undefined && this.props.images.length > 1,
      quantity: 1,
      selectedOption: this.props.options[0],
    };
  }
  render() {
    return (
      <div className="provision">
        <div className="provision-header">
          <NavLink className="provision-backLink" to="/provisions" exact={true}>
            <div className="provision-back">
              <Icon className="provision-backIcon">reply</Icon>
              All Provisions
            </div>
          </NavLink>
        </div>
        <div className="provision-title">{this.props.title}</div>
        <div className="provision-content">
          {this.props.description.length > 0 && (
            <div className="provision-description">
              {this.props.description.map((paragraph: string, i: number) => (
                <div key={i} className="provision-paragraph">
                  {paragraph}
                </div>
              ))}
            </div>
          )}
          <Separator />
          <div className="provision-images">
            <div
              className="provision-imageMain"
              style={{ backgroundImage: `url(${this.state.selectedImage})` }}
            />
            {this.props.images && this.state.multipleImages && (
              <div className="provision-imageTiles">
                {this.props.images.map((image: string, i: number) => (
                  <div
                    key={i}
                    className={`provision-imageTile ${
                      image === this.state.selectedImage
                        ? "imageTileSelected"
                        : undefined
                    }`}
                    style={{ backgroundImage: `url(${image})` }}
                    onClick={() => this.setState({ selectedImage: image })}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="provision-about">
            <div className="provision-price">
              $
              {(
                this.state.selectedOption.optionPrice * this.state.quantity
              ).toFixed(2)}
              <div className="provision-quantity">
                <input
                  className="provision-quantityInput"
                  type="number"
                  min={1}
                  step={1}
                  value={this.state.quantity}
                  onChange={(e: any) =>
                    this.setState({ quantity: Math.max(1, e.target.value) })
                  }
                />
              </div>
              <div className="provision-addToCart">
                <button
                  className="provision-addButton snipcart-add-item"
                  data-item-id={
                    this.props.title + " " + this.state.selectedOption.option
                  }
                  data-item-name={
                    this.props.title + " " + this.state.selectedOption.option
                  }
                  data-item-price={`${this.props.options[0].optionPrice.toFixed(
                    2
                  )}`}
                  data-item-weight={this.state.selectedOption.optionWeight}
                  data-item-quantity={`${this.state.quantity}`}
                  data-item-taxable="false"
                  data-item-url="/index.html"
                >
                  Add to Cart
                </button>
              </div>
            </div>
            <div className="provision-options">
              {this.props.options.map(
                (
                  option: { optionPrice: number; option: string },
                  i: number
                ) => {
                  return (
                    <div
                      key={i}
                      className={`provision-option ${
                        option === this.state.selectedOption
                          ? "provision-optionSelected"
                          : undefined
                      }`}
                      onClick={() => this.setState({ selectedOption: option })}
                    >
                      {option.option}
                    </div>
                  );
                }
              )}
            </div>

            {this.props.ingredients && (
              <div className="provision-ingredients">
                Ingredients:
                <div className="provision-ingredientsList">
                  {this.props.ingredients
                    .map((ingredient: string, i: number) => ingredient)
                    .join(", ")}
                </div>
              </div>
            )}
            {this.props.notes && (
              <div className="provision-notesContainer">
                Notes: <div className="provision-notes">{this.props.notes}</div>
              </div>
            )}
          </div>
        </div>
        {this.props.tags && (
          <div className="provision-tags">
            {this.props.tags.map((tag: string) => tag)}
          </div>
        )}
      </div>
    );
  }
}

export default Provision;
