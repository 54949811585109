import './News.scss';

import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { NavLink } from 'react-router-dom';

import { Icon } from '@material-ui/core';

import { NewsPageProps, NewsProps } from '../../../../Interfaces/Props';
import news from '../../../../Media/images/newspaper.jpg';
import wood from '../../../../Media/images/wood.jpg';
import Separator2 from '../../Separator/Separator2';
import makeLink from '../../Tools/LinkMaker';

interface propsWithHeight {
  props: NewsProps;
  height: string | null;
}

class News extends Component<NewsPageProps> {
  state: {
    sortedArticles: propsWithHeight[];
    search: string;
  };

  constructor(props: NewsPageProps) {
    super(props);
    this.state = {
      sortedArticles: this.props.articles
        .sort((a: NewsProps, b: NewsProps) => {
          const [monthA, dayA, yearA] = a.date.split("/");
          const [monthB, dayB, yearB] = b.date.split("/");
          if (yearA !== yearB) return +yearB - +yearA;
          if (monthA !== monthB) return +monthB - +monthA;
          return +dayB - +dayA;
        })
        .map((article: NewsProps) => {
          return {
            props: article,
            height: null, // default to css
          };
        }),
      search: "",
    };
  }

  componentDidMount() {
    window.addEventListener("resize", () => this.squareImages());
    this.squareImages();
  }

  squareImages() {
    const image = document.getElementsByClassName("news-articleImage")[0];
    if (image && this.state.sortedArticles) {
      this.setState({
        sortedArticles: this.state.sortedArticles.map(
          (props: propsWithHeight) => {
            return {
              props: props.props,
              height: `${
                props.props.images && typeof props.props.images[0] === "string"
                  ? image.clientWidth
                  : null
              }`,
            };
          }
        ),
      });
    }
  }

  render() {
    const filtered = this.state.sortedArticles.filter(
      (article: propsWithHeight) => {
        const articleSearch = { ...article.props };
        articleSearch.images = [];
        return Object.values(articleSearch)
          .join(" ")
          .toLowerCase()
          .includes(this.state.search.toLowerCase());
      }
    );
    return (
      <div id="news">
        <Helmet>
          <title>News</title>
          <meta
            name="description"
            content="Keep up to date with news about Caulfield Provision Company."
          />
        </Helmet>
        <div id="news-header" style={{ backgroundImage: `url(${news})` }}>
          <div id="news-headerText">News</div>
        </div>
        <div id="news-about">
          This is the place to find out what we are doing, what we are thinking
          (whether it is hot sauce/food related or musings on life in general),
          or what other people are thinking about us!
        </div>

        <div id="news-search">
          <Icon id="news-searchIcon">search</Icon>
          <input
            id="news-searchInput"
            type="text"
            name="newsearch"
            placeholder="Search For Articles"
            value={this.state.search}
            onChange={(e) => this.setState({ search: e.target.value })}
          />
          <div id="news-resultCount">Showing {filtered.length} Articles</div>
        </div>
        <Separator2 />
        {filtered.length === 0 && <div id="news-noResults">No Results</div>}
        <div
          id="news-articles"
          style={{
            backgroundImage: `url(${wood})`,
          }}
        >
          <div id="news-background">
            {filtered.map((article: propsWithHeight, i: number) => (
              <NavLink
                key={i}
                exact={true}
                to={`/news/${makeLink(article.props.title)}`}
                className="news-articleLink"
                style={{ textDecoration: "none" }}
              >
                <div className="news-article">
                  <div className="news-articleTitleAndDate">
                    <div className="news-articleDate">{article.props.date}</div>
                    <div className="news-articleTitle">
                      {article.props.title}
                    </div>
                  </div>
                  {(article.props.content || article.props.list) &&
                    article.props.images &&
                    typeof article.props.images[0] === "string" && (
                      <div className="news-articleImageAndText">
                        <div
                          className="news-articleImage"
                          style={{
                            backgroundImage: `url(${article.props.images[0]})`,
                            height: `${
                              article.height ? `${article.height}px` : "auto"
                            }`,
                          }}
                        />
                        <div className="news-articleTextContainer">
                          <div className="news-articleText">
                            {article.props.content && (
                              <div className="news-articleParagraphs">
                                {article.props.content.map(
                                  (paragraph: string, i: number) => (
                                    <div
                                      key={i}
                                      className="news-articleParagraph"
                                    >
                                      {paragraph}
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                          </div>
                          {article.props.list && (
                            <>
                              <div className="news-articleListTitle">
                                {article.props.list.listTitle}
                              </div>
                              <ul className="news-articleList">
                                {article.props.list.listContent.map(
                                  (item: string, i: number) => (
                                    <li
                                      key={i}
                                      className="news-articleListItem"
                                    >
                                      {item}
                                    </li>
                                  )
                                )}
                              </ul>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  {(!article.props.images ||
                    !article.props.images[0] ||
                    !(article.props.content || article.props.list)) && (
                    <>
                      {article.props.content && (
                        <div className="news-articleText">
                          {article.props.content && (
                            <div className="news-articleParagraphs">
                              {article.props.content.map(
                                (paragraph: string, i: number) => (
                                  <div
                                    key={i}
                                    className="news-articleParagraph"
                                  >
                                    {paragraph}
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      )}
                      {article.props.images &&
                        typeof article.props.images[0] === "string" && (
                          <div
                            className="news-articleImage"
                            style={{
                              backgroundImage: `url(${article.props.images[0]})`,
                              height: `${
                                article.height ? `${article.height}px` : "auto"
                              }`,
                            }}
                          />
                        )}
                      {article.props.list && (
                        <>
                          <div className="news-articleListTitle">
                            {article.props.list.listTitle}
                          </div>
                          <ul className="news-articleList">
                            {article.props.list.listContent.map(
                              (item: string, i: number) => (
                                <li key={i} className="news-articleListItem">
                                  {item}
                                </li>
                              )
                            )}
                          </ul>
                        </>
                      )}
                    </>
                  )}
                  <div className="news-articleReadMore">Read More</div>
                </div>
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default News;
