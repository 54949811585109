import './Provisions.scss';

import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { NavLink } from 'react-router-dom';

import { Icon } from '@material-ui/core';

import { Category, ProvisionProps, ProvisionsPageProps } from '../../../../Interfaces/Props';
import placeholder from '../../../../Media/graphics/logoCircle.svg';
import header from '../../../../Media/images/sauces.jpg';
import wood from '../../../../Media/images/wood.jpg';
import Separator from '../../Separator/Separator';
import Separator2 from '../../Separator/Separator2';
import makeLink from '../../Tools/LinkMaker';

class Provisions extends Component<ProvisionsPageProps> {
  state: {
    sortedProvisions: { [displayOrder: number]: ProvisionProps[] };
    sortedCategories: Category[];
    search: string;
  };

  constructor(props: ProvisionsPageProps) {
    super(props);
    const sorted: { [displayOrder: number]: ProvisionProps[] } = {};
    const keys: number[] = [];

    props.provisions
      .sort((a: ProvisionProps, b: ProvisionProps) => {
        if (a.category.displayOrder !== b.category.displayOrder)
          return a.category.displayOrder - b.category.displayOrder;
        else return a.title > b.title ? 1 : -1;
      })
      .map((provision: ProvisionProps) => {
        if (sorted[provision.category.displayOrder])
          sorted[provision.category.displayOrder].push(provision);
        else {
          sorted[provision.category.displayOrder] = [provision];
          keys.push(provision.category.displayOrder);
        }
        return null;
      });
    const categories: Category[] = keys
      .map((key: number) => sorted[key][0].category)
      .sort((a: Category, b: Category) => a.displayOrder - b.displayOrder);

    this.state = {
      sortedProvisions: sorted,
      sortedCategories: categories,
      search: "",
    };
  }

  render() {
    let count = 0;
    const filtered: { [displayOrder: number]: ProvisionProps[] } = {};
    let matches = 0;
    this.state.sortedCategories.map((category: Category) => {
      const results = this.state.sortedProvisions[category.displayOrder].filter(
        (provision: ProvisionProps) => {
          const provisionSearch = { ...provision };
          provisionSearch.images = [];
          const result = Object.values(provisionSearch)
            .join(" ")
            .toLowerCase()
            .includes(this.state.search.toLowerCase());
          if (result) matches++;
          return result;
        }
      );
      if (results) filtered[category.displayOrder] = results;
      return results;
    });

    return (
      <div id="provisions">
        <Helmet>
          <title>Provisions</title>
          <meta
            name="description"
            content="Caulfield Provision Company's finest selection of gourmet hot sauces and marinades. Buy our all natural, healthy, and delicious provisions right online here!"
          />
        </Helmet>

        <div
          id="provisions-header"
          style={{ backgroundImage: `url(${header})` }}
        >
          <div id="provisions-headerText">Provisions</div>
        </div>
        <div id="provisions-about">
          Buy our healthy and flavorful provisions here!
        </div>

        <div id="provisions-search">
          <Icon id="provisions-searchIcon">search</Icon>
          <input
            id="provisions-searchInput"
            type="text"
            name="provisionssearch"
            placeholder="Search For Provisions"
            value={this.state.search}
            onChange={(e) => this.setState({ search: e.target.value })}
          />
          <div id="provisions-resultCount">Showing {matches} Provisions</div>
        </div>
        <Separator2 />
        {matches === 0 && <div id="provisions-noResults">No Results</div>}
        <div
          id="provisions-listings"
          style={{
            backgroundImage: `url(${wood})`,
          }}
        >
          {this.state.sortedCategories.map((category: Category, i: number) => {
            if (filtered[category.displayOrder].length > 0) {
              count++;
              return (
                <div key={i} className="provisions-category">
                  {count > 1 && <Separator />}
                  <div className="provisions-categoryName">
                    {category.category}
                  </div>
                  <div className="provisions-categoryDescription">
                    {category.description}
                  </div>
                  <div className="provisions-categoryListings">
                    {filtered[category.displayOrder].map(
                      (provision: ProvisionProps, i: number) => (
                        <NavLink
                          exact={true}
                          to={`/provisions/${makeLink(provision.title)}`}
                          className="provisions-link"
                          key={i}
                          style={{ textDecoration: "none" }}
                        >
                          <div
                            className="provisions-listing"
                            style={{
                              backgroundImage: `url(${
                                provision.images
                                  ? provision.images[0]
                                  : placeholder
                              })`,
                            }}
                          >
                            {provision.new && (
                              <div className="provisions-new">New</div>
                            )}
                            <div className="provisions-provisionTitle">
                              {provision.title}
                            </div>
                          </div>
                        </NavLink>
                      )
                    )}
                  </div>
                </div>
              );
            } else return null;
          })}
        </div>
      </div>
    );
  }
}

export default Provisions;
