import React from 'react';
import ReactDOMServer from 'react-dom/server';

import { Link, TextProcessorProps } from '../Interfaces/Props';

class TextProcessor extends React.Component<TextProcessorProps> {
  render() {
    return addLinks(this.props.text, this.props.links);
  }
}

export default TextProcessor;

function addLinks(text: string, links: Link[] | undefined) {
  if (links === undefined) return text;

  let result = "";
  let remainingText = text;

  while (remainingText.length > 0) {
    // traverse string, split one at a time based on the nearest split index
    let link: Link = { url: "", text: "" };
    const closestSplit: {
      alterations: any;
      index: number;
    } = {
      alterations: link,
      index: Number.MAX_SAFE_INTEGER
    };

    if (links.length !== 0)
      for (let i = 0; i < links.length; i++) {
        let index = remainingText.indexOf(links[i].text);
        if (index !== -1 && index < closestSplit.index) {
          closestSplit.index = index;
          closestSplit.alterations = links[i];
        }
      }

    if (closestSplit.index === Number.MAX_SAFE_INTEGER) {
      result = result + remainingText;
      remainingText = "";
      break;
    }

    let splitResult = remainingText.split(closestSplit.alterations.text, 1);

    let leftText = splitResult[0];
    let rightText = remainingText.substring(
      remainingText.indexOf(closestSplit.alterations.text) +
        closestSplit.alterations.text.length
    );

    let linkText = (
      <a className="content-linkText" href={`${closestSplit.alterations.url}`}>
        {closestSplit.alterations.text
          .split(" ")
          .map((word: string, i: number) => (
            <span key={i} className="article-paragraphWord article-resizable">
              {`${word} `}
            </span>
          ))}
      </a>
    );

    remainingText = rightText;

    if (result.length === 0) {
      result = leftText + ReactDOMServer.renderToStaticMarkup(linkText);
    } else {
      result =
        result + leftText + ReactDOMServer.renderToStaticMarkup(linkText);
    }
  }

  return (
    <span
      className="content-styledText"
      dangerouslySetInnerHTML={iLiveDangerously(result)}
    />
  );
}

function iLiveDangerously(html: string) {
  return { __html: html };
}
